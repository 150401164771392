import AuthStateInspector from './AuthStateInspector/AuthStateInspector';
import EntitlementsInspector from './EntitlementsInspector/EntitlementsInspector';
import SideBannersTrigger from './SideBannersTrigger/SideBannersTrigger';
import type { DebuggingToolComponent } from './types';
import UpsellModalsTrigger from './UpsellModalsTrigger/UpsellModalsTrigger';

export const DEBUGGING_TOOL_COMPONENTS_REGISTRY: DebuggingToolComponent[] = [
  AuthStateInspector,
  EntitlementsInspector,
  UpsellModalsTrigger,
  SideBannersTrigger
];

import { hideUpsellModal } from 'modules/upsells/stores/actions/hideUpsellModal';
import { showUpsellModal } from 'modules/upsells/stores/actions/showUpsellModal';
import { UpsellModalType } from 'modules/upsells/stores/types';

import { DebuggingToolComponent } from '../types';

const modals = {
  [UpsellModalType.ContextualAskAI]: 'Ask AI',
  [UpsellModalType.ContextualCloneVoice]: 'Clone Voice',
  [UpsellModalType.ContextualDownloadMP3]: 'Download MP3',
  [UpsellModalType.ContextualImageOnlyPDFs]: 'Image Only PDFs',
  [UpsellModalType.ContextualIncreaseSpeed]: 'Increase Speed',
  [UpsellModalType.ContextualPremiumVoices]: 'Premium Voices',
  [UpsellModalType.ContextualSkipHeadersFooters]: 'Skip Headers & Footers',
  [UpsellModalType.ContextualSupport]: '24/7 Support',
  [UpsellModalType.ContextualUnlimitedFiles]: 'Unlimited Files',
  [UpsellModalType.Global]: 'Global Upsell'

  // legacy

  // [UpsellModalType.FreeWordLimit]: 'Free Word Limit',
  // [UpsellModalType.TempWordLimit]: 'Temp Word Limit',
  // [UpsellModalType.UpgradeForScan]: 'Upgrade For Scan'
};

const UpsellModalsTrigger = () => {
  const modalEntries = Object.entries(modals);

  const modalPairs = Array.from({ length: Math.ceil(modalEntries.length / 2) }, (_, i) => modalEntries.slice(i * 2, i * 2 + 2));

  return (
    <div className="mt-4 flex flex-col">
      <div className="text-lg font-bold mb-2">Upsell Modal Triggers</div>

      {modalPairs.map((pair, rowIndex) => (
        <div key={`row-${rowIndex}`} className="flex gap-4 mb-2">
          {pair.map(([modalType, label]) => (
            <button
              key={modalType}
              onClick={() => showUpsellModal(modalType as UpsellModalType)}
              className="flex-1 text-glass-500 bg-blue-100 border-b border-black rounded-100 hover:border-gray-400 py-1"
            >
              {label}
            </button>
          ))}
          {pair.length === 1 && <div className="flex-1" />}
        </div>
      ))}

      <button onClick={hideUpsellModal} className="text-glass-500">
        Hide Modal
      </button>
    </div>
  );
};

export default {
  component: UpsellModalsTrigger,
  condition: async () => true
} satisfies DebuggingToolComponent;

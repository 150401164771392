import { X } from 'lucide-react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import TextareaAutosize from 'react-textarea-autosize';

type Emoji = '🥴' | '😒' | '😊' | '😁' | '🤩';
type EmojiRating = { emoji: Emoji; value: number };

const emojiRatings: EmojiRating[] = [
  { emoji: '🥴', value: 1 },
  { emoji: '😒', value: 2 },
  { emoji: '😊', value: 3 },
  { emoji: '😁', value: 4 },
  { emoji: '🤩', value: 5 }
];

interface CSATPopupProps {
  onRatingSubmit: (value: number) => void;
  onFeedbackSubmit: (value: number, feedback: string) => void;
  onDismiss: () => void;
  lowRatingThreshold?: number;
}

const ThankYouSVG = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={100} height={101} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.1043 32.3136C4.82905 21.7332 5.19143 16.4431 10.8693 10.7792C16.5471 5.11531 21.7977 4.76861 32.2991 4.0752C37.5492 3.72854 43.6195 3.5 50.5 3.5C57.3805 3.5 63.4508 3.72854 68.7009 4.0752C79.2023 4.76861 84.4529 5.11531 90.1307 10.7792C95.8086 16.4431 96.171 21.7333 96.8957 32.3136C97.2586 37.6116 97.5 43.6937 97.5 50.5C97.5 56.9134 97.2857 62.6838 96.9573 67.7611C96.2389 78.8691 95.8797 84.423 90.2044 90.1125C84.529 95.8019 78.9364 96.1776 67.7511 96.9288C62.6235 97.2732 56.8401 97.5 50.5 97.5C44.1599 97.5 38.3765 97.2732 33.2489 96.9288C22.0636 96.1776 16.471 95.8019 10.7956 90.1125C5.12028 84.423 4.76108 78.8691 4.04269 67.7611C3.71432 62.6838 3.5 56.9134 3.5 50.5C3.5 43.6937 3.74138 37.6116 4.1043 32.3136Z"
      className="fill-[#FFF1DE] dark:fill-[#2E2E2E]"
    />
    <path
      d="M57.4625 39.1613L77.8516 21.0536"
      className="stroke-[#FF9805] dark:stroke-[#D3992C]"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.3336 80.3018C25.3336 80.3018 41.8891 70.2478 45.7228 62.1942"
      className="stroke-[#F18E00] dark:stroke-[#D3992C]"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={51.095} cy={49.352} r={26.6915} transform="rotate(21.916 51.095 49.352)" fill="url(#paint0_linear)" />
    <mask id="mask0" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x={24} y={22} width={54} height={55}>
      <circle cx={51.095} cy={49.352} r={26.6915} transform="rotate(21.916 51.095 49.352)" fill="url(#paint1_linear)" />
    </mask>
    <g mask="url(#mask0)">
      <g filter="url(#filter0_f)" className="dark:opacity-100 opacity-0">
        <ellipse cx={49.3856} cy={36.2462} rx={5.1119} ry={2.22429} transform="rotate(6.78408 49.3856 36.2462)" fill="#FF2C6B" />
      </g>
      <g filter="url(#filter1_f)" className="dark:opacity-100 opacity-0">
        <ellipse cx={66.702} cy={31.0118} rx={5.1119} ry={2.22429} transform="rotate(6.78408 66.702 31.0118)" fill="#FF2C6B" />
      </g>
    </g>
    <path
      d="M50.8301 44.1198C52.9403 46.002 56.9888 47.6225 60.411 47.0749C66.9293 46.0319 73.0166 38.1312 76.6669 32.998"
      stroke="url(#paint2_linear)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0394 47.2909L26.9479 35.8302C27.4694 38.8443 30.2049 43.7376 36.1957 44.6812C30.3999 51.4334 27.5002 55.0235 21.0103 63.3084C13.2993 60.0884 13.3796 51.7811 16.0394 47.2909Z"
      fill="#FF2C6B"
      stroke="#FF2C6B"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.0374 81.7957C39.0374 81.7957 65.9082 74.2004 47.1334 68.7612"
      stroke="url(#paint3_linear)"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle cx={54.1992} cy={29.4062} r={1.85932} className="fill-black dark:fill-[#1E1E1E]" />
    <circle cx={61.8886} cy={27.5468} r={1.85932} className="fill-black dark:fill-[#1E1E1E]" />
    <path
      d="M56.7539 33.877C58.3231 34.8571 60.2784 34.4645 61.5132 33.9608"
      className="stroke-black dark:stroke-[#1E1E1E]"
      strokeWidth={2.4}
      strokeLinecap="round"
    />
    <defs>
      <filter id="filter0_f" x={35.3771} y={25.0295} width={28.0173} height={22.4333} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={4.46281} result="effect1_foregroundBlur" />
      </filter>
      <filter id="filter1_f" x={52.6935} y={19.7951} width={28.0173} height={22.4333} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation={4.46281} result="effect1_foregroundBlur" />
      </filter>
      <linearGradient id="paint0_linear" x1={57.9853} y1={35.483} x2={29.1226} y2={66.4879} gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFC044" />
        <stop offset={1} stopColor="#FF9500" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1={51.095} y1={22.6605} x2={74.45} y2={74.4547} gradientUnits="userSpaceOnUse">
        <stop stopColor="#E4B84A" />
        <stop offset={1} stopColor="#E99E2D" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1={102.361} y1={21.5369} x2={50.8353} y2={12.2619} gradientUnits="userSpaceOnUse">
        <stop offset={0.463876} stopColor="#FFE5AB" />
        <stop offset={0.897924} stopColor="#E7AB41" />
      </linearGradient>
      <linearGradient id="paint3_linear" x1={91.4243} y1={61.1611} x2={75.027} y2={40.7494} gradientUnits="userSpaceOnUse">
        <stop offset={0.376171} stopColor="#FFE5AB" />
        <stop offset={0.827737} stopColor="#E8A434" />
      </linearGradient>
    </defs>
  </svg>
);

const FEEDBACK_THANK_YOU_DURATION = 3000;

const CSATPopup: React.FC<CSATPopupProps> = ({ onRatingSubmit, onFeedbackSubmit, onDismiss }) => {
  const [stage, setStage] = useState<'rating' | 'feedback' | 'thankyou'>('rating');
  const [selectedRating, setSelectedRating] = useState<number | null>(null);

  const {
    register,
    handleSubmit,
    formState: { isValid }
  } = useForm<{ feedback: string }>();

  const handleRatingClick = (value: number) => {
    setSelectedRating(value);
    onRatingSubmit(value);
    setStage('feedback');
  };

  const onSubmit = (data: { feedback: string }) => {
    if (selectedRating !== null) {
      onFeedbackSubmit(selectedRating, data.feedback);
      setStage('thankyou');
      setTimeout(onDismiss, FEEDBACK_THANK_YOU_DURATION);
    }
  };

  return (
    <div className="bg-surface-primary relative shadow-spl-200 rounded-200 p-spl-9 w-[280px] flex flex-col gap-spl-7">
      <div className="absolute top-spl-2 right-spl-2">
        <button onClick={onDismiss} className="text-icon-secondary">
          <X size={20} />
        </button>
      </div>
      {stage === 'rating' && (
        <>
          <h2 className="text-heading-5 text-text-primary text-center px-spl-4">Rate your experience listening to this file</h2>
          <div className="flex justify-between">
            {emojiRatings.map(({ emoji, value }) => (
              <button className="cursor-pointer" key={value} onClick={() => handleRatingClick(value)}>
                <span className="text-[28px] rounded-full px-spl-2 pt-spl-1 hover:bg-bg-accent">{emoji}</span>
              </button>
            ))}
          </div>
        </>
      )}
      {stage === 'feedback' && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className="text-heading-6 text-text-primary mb-spl-4">Please tell us what went wrong</h2>
          <TextareaAutosize
            id="feedback"
            maxRows={7}
            minRows={5}
            autoFocus
            className="rounded-200 w-full px-spl-6 py-spl-5 resize-none text-body-6 placeholder:text-text-placeholder text-text-primary bg-surface-primary border border-border-primary mb-spl-4"
            placeholder="Share your feedback here..."
            {...register('feedback', { required: true, minLength: 1 })}
          />
          <button
            type="submit"
            disabled={!isValid}
            className="bg-button-primary-cta rounded-250 text-text-button-primary-cta text-subheading-6 py-spl-4 px-spl-7 w-full disabled:bg-button-disabled disabled:text-text-disabled"
          >
            Submit Feedback
          </button>
        </form>
      )}
      {stage === 'thankyou' && (
        <div className="text-center">
          <div className="mb-spl-7 grid place-items-center">
            <ThankYouSVG />
          </div>
          <h2 className="text-heading-5 text-text-primary mb-spl-4">Thank you!</h2>
          <p className="text-body-6 text-text-secondary">Thanks for sharing your thoughts. We are always trying to improve.</p>
        </div>
      )}
    </div>
  );
};

export default CSATPopup;

import { useEffect, useState } from 'react';

import { DEBUGGING_TOOL_COMPONENTS_REGISTRY } from '../components';

export function useDebuggingToolsComponents() {
  const [visibleComponents, setVisibleComponents] = useState<React.ComponentType[]>([]);

  useEffect(() => {
    const loadComponents = async () => {
      const componentsToRender: React.ComponentType[] = [];

      for (const { component, condition } of DEBUGGING_TOOL_COMPONENTS_REGISTRY) {
        if (!condition) {
          continue;
        }

        const shouldRender = await condition();

        if (shouldRender) {
          componentsToRender.push(component);
        }
      }

      setVisibleComponents(componentsToRender);
    };

    loadComponents();
  }, []);

  return visibleComponents;
}

import * as React from 'react';

function MoveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="move">
        <g id="icon">
          <rect id="Rectangle 2" x="12.5" y="12.5" width="2" height="2" rx="1" transform="rotate(-180 12.5 12.5)" fill="#8791A0" />
          <rect id="Rectangle 2_2" x="9" y="12.5" width="2" height="2" rx="1" transform="rotate(-180 9 12.5)" fill="#8791A0" />
          <rect id="Rectangle 3" x="12.5" y="9" width="2" height="2" rx="1" transform="rotate(-180 12.5 9)" fill="#8791A0" />
          <rect id="Rectangle 3_2" x="9" y="9" width="2" height="2" rx="1" transform="rotate(-180 9 9)" fill="#8791A0" />
        </g>
      </g>
    </svg>
  );
}

export default MoveIcon;
